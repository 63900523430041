<template lang="pug">
span
  slot(:download="download")
    v-btn(
      color="primary"
      @click="download"
      :disabled="disabled"
      outlined
      small
    ) Download student photo
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin';
import { downloadFile } from '@/util/index.js'

export default {
  inject: ['candidatesActionsSvc'],

  mixins: [errorsMixin],

  props: {
    disabled: Boolean,
    courseID: Number,
    activeItems: Array,
    consentOnly: Boolean
  },

  methods: {
    async download() {
      try {
        const candidatesIDList = this.activeItems.map(item => item.ID);
        let res = await this.candidatesActionsSvc().bulkDownloadCoursePhoto(candidatesIDList, this.consentOnly)
        const fileName = this.consentOnly ? `${this.courseID}_course_photos_with_consent_only.zip`: `${this.courseID}_course_photo.zip`
        downloadFile(res, fileName, 'application/zip')
        this.$notify({text: 'Successfully downloaded', type: 'success'})
      } catch (err) {
        this.processError(err)
      }
    }
  },
}
</script>
